<template>
  <WhatWeDo
    :background-image="this.backgroundImage"
    :background-image-details="'Image Description'"
  >
  <h1>What We Do - Exhibitions</h1>
  <div class="scrollable-content">
    <p>
          Make It So have over 20 years experience in the exhibition industry
          using moderated interactive activities at exhibition booths and
          stands. Coupled with an engaging, experienced presenter we can offer
          businesses our
          <router-link
            ref="navLink"
            class="navlink"
            to="/what-we-do/exhibitions/ipad-interactive-voting-system"
            ><span class="nav-link"
              >iPad Interactive Voting System</span
            ></router-link
          >
          and our
          <router-link
            ref="navLink"
            class="navlink"
            to="/what-we-do/exhibitions/keypad-interactive-voting-system"
            ><span class="nav-link"
              >Keypad Interactive Voting System</span
            ></router-link
          >.
        </p>
        <p>
          A similar system can be used in an online virtual activity where
          delegates can join remotely as our team educate and engage delivering
          the same level of entertainment over the Internet.
        </p>
        <p>
          In addition to our congress work, we have been helping businesses with
          internal team training where we support by preparing key personnel to
          be dynamic in their workplace, especially on the booth at exhibitions.
          <br />Here we delight in passing on skills in good communication,
          engaging with customers, dealing with anxiety issues, work
          satisfaction and delivering overall excitement prior to and during the
          exhibition run.
        </p>
        <p>
          Recently we have been facilitating businesses in their
          <router-link
            ref="navLink"
            class="navlink"
            to="/what-we-do/exhibitions/virtual-meetings"
            ><span class="nav-link">Live Virtual Meetings</span></router-link
          >
          but overall Make It So can supply you with engaging events that
          include presenting and moderating a variety of interactive activities
          with
          <router-link
            ref="navLink"
            class="navlink"
            to="/what-we-do/exhibitions/congress-booth-training"
            ><span class="nav-link">Congress Booth Training</span></router-link
          >, hosting panel discussions, award ceremonies, competitions, quizzes
          all whilst linking each presentation in a professional manner.
        </p>
        <p>
          We also offer
          <router-link
            ref="navLink"
            class="navlink"
            to="/what-we-do/exhibitions/presentation-skills-training"
            ><span class="nav-link"
              >Presentation Skills Training</span
            ></router-link
          >
          for those presenting at an exhibition, event or simply helping
          employees in the workplace present in front of a live and/or recorded
          audience.
        </p>
        <p>
          Our experienced trainer teaches the dynamic and physical skills that
          can support a speaker, from controlling anxiety to expressing clarity
          of thought using tone and texture of voice to aid in delivering a
          power-house presentation that truly engages with an audience.
        </p>
        <p>
          We have supplied voice-over and directed artists for a variety of
          corporate product and information videos in the past. Companies have
          included names such as Bayer Healthcare, Southern Water, and the
          Brightwave Group. Our past experience in both the theatre and
          corporate environments enables us to work with precision in directing
          and delivering what is required by our clients. Leaving a highly
          postive, memorable experience.
        </p><p>You can view all the companies we work with both past and present
         <router-link
            ref="navLink"
            class="navlink"
            to="/portfolio"
            ><span class="nav-link">here</span></router-link
          >.</p>
  </div>
  </WhatWeDo>
</template>
<script>
import WhatWeDo from '@/components/WhatWeDo.vue'
export default {
  name: 'ExhibitionsPage',
  components: { WhatWeDo },
  data() {
    return {
      backgroundImage: require('@/assets/images/booth-activity-background.jpg'),
    }
  },
}
</script>
